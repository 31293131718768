<template>
    <div class="shiyo-viewer-link">
        <v-card >
                <v-toolbar elevation="0" color="#414241" dark dense>
                        <h3 style="font-family:Arial;color:white;font-weight:bold;" class="mt-1"> {{shiyoDocumentDetails.productName}} </h3>
                       
                        <v-spacer></v-spacer>

                        <v-tooltip bottom transition="scroll-y-transition">
                            <template v-slot:activator="{on}">
                                <v-btn @click="isFavorite = !isFavorite; addFavorite()" :disabled="isPDFLoadError" v-on="on" class="mr-3" text small color="white">
                                    <v-icon :color="isFavorite ? 'yellow' : 'white'">{{ isFav }}</v-icon>
                                    <span>Favorite </span>
                                </v-btn>
                            </template>
                            <span> Add Favorite </span>
                        </v-tooltip>
                        
                         <v-tooltip bottom transition="scroll-y-transition">
                            <template v-slot:activator="{on}">
                                  <v-btn @click="goToFeedback()" :disabled="isPDFLoadError" text small v-on="{on}" class="mr-3" >
                                    <v-icon color="white">mdi-comment-quote</v-icon>
                                    <span style="color:white"> Feedback </span>
                                </v-btn>
                            </template>
                            <span> Feedback </span>
                        </v-tooltip>


                        <v-tooltip bottom transition="scroll-y-transition">
                            <template v-slot:activator="{on}">
                                <v-btn @click="printPDF()" :disabled="isPDFLoadError" v-on="on" class="mr-3" text small color="white">
                                    <v-icon> mdi-printer </v-icon>
                                    Print
                                </v-btn>
                            </template>
                            <span> Print PDF </span>
                        </v-tooltip>
            
                        <v-tooltip bottom transition="scroll-y-transition">
                            <template v-slot:activator="{on}">
                                <v-btn :disabled="isPDFLoadError" @click="downloadPDF()" v-on="on" text  small color="white;" class="mr-5">
                                    <v-icon> mdi-download</v-icon>
                                    Download
                                </v-btn>
                            </template>
                            <span> Download File </span>
                        </v-tooltip>

                </v-toolbar>
                <center> <h1 v-if="isPDFLoadError" class="mt-5" style="font-family:Arial"> No Attachment Found, Please contact Administrator.. </h1></center>
                <center> <h1 v-if="!isPDFLoadError && !isPDFLoad" class="mt-5" style="font-family:Arial"> Please Wait While Opening PDF File </h1></center>
                <center> <v-img v-if="!isPDFLoadError && !isPDFLoad"  max-width="500" max-height="500" :src ="PDFloadingGIF" > </v-img>  </center>
        <iframe v-if="isPDFLoad" :src="`${src}#toolbar=0`" frameborder="0" style="margin:0; padding:0px;border:0;top:0px;left:0px;bottom:0px;right:0px;" width="100%"  height="100%" allowfullscreen ></iframe>
        </v-card>
    </div>
    <!-- <PDFView
        v-if="isPDFLoad"
        :src.sync="src"
        ref="pdfView"
        :fileName="shiyoDocumentDetails.fileName"
        :sidebarFeatureVisible="true"
        :downloadFeatureVisible="true"
        :toolbarVisible="true"
        :scale.sync="scale"
    >

    <template slot="left-toolbox">
        <span class="ml-4 fStyle">{{shiyoDocumentDetails.productName}}</span>
        <v-btn fab icon small @click="zoomIn()">
            <v-icon color="white">mdi-magnify-plus-outline</v-icon>
        </v-btn>
        <v-btn fab icon small @click="zoomOut()">
            <v-icon color="white">mdi-magnify-minus-outline</v-icon>
       </v-btn>

       <input ref="autoFocus" type="text" style="height:1px;width:1px" @keydown="handleCtrlKey" @keyup="handleUnpressCTRL"/>


       <v-spacer></v-spacer>
        <a-tooltip placement="bottom">
            <template #title>
                <span> Print </span>
            </template>
            <v-btn @click="pdfPrint()" text small >
                <v-icon color="white">mdi-printer</v-icon>
                <span style="color:white"> Print </span>
            </v-btn>
        </a-tooltip>

        <a-tooltip placement="bottom">
            <template #title>
                <span> Feedback </span>
            </template>
            <v-btn @click="goToFeedback()" text small >
                <v-icon color="white">mdi-comment-quote</v-icon>
                <span style="color:white"> Feedback </span>
            </v-btn>
        </a-tooltip>

         <a-tooltip placement="bottom">
            <template #title>
                <span> Download </span>
            </template>
               <v-btn  @click="downloadPDF()" text small > 
                <v-icon color="white">mdi-download</v-icon>
                <span style="color:white"> Download </span>
            </v-btn>

        </a-tooltip>

    
        <a-tooltip placement="bottom">
            <template #title>
                <span>Back</span>
            </template>
            <v-btn @click="CloseWindow()" text small>
                <v-icon color="white">mdi-arrow-left-bottom-bold</v-icon>
                <span style="color:white"> Back </span>
            </v-btn>
        </a-tooltip>
    </template>

    <template slot="error"> <span>&nbsp;</span>	</template>
     <template slot="loading"></template>		
    </PDFView> -->
</template>

<script>
import AWS from "aws-sdk";
import tunnel from "tunnel";
import config from "../config";
import axios from "axios";
import * as binconv from "binconv";
import Swal from "sweetalert2";
// import { PDFView } from "vue_pdfjs_viewer";

export default {
	props: {
		memoDetails: Object,
		linkURLBlob: String
	},
	components: {
		// PDFView,
	},
	data() {
		return {
			sidePage: false,
			scale: "auto",
			src: "",
            shiyoDocumentDetails: {}, 
            isPDFLoad: false,
            browserZoomLevel: 0,
            isPDFRendered: false,
			isPDFLoadError: false,
            PDFloadingGIF: require('../assets/PDFLoading3.gif'),
            isFavorite: false,
            shiyoData: {
                id: "",
                shiyoNumber: "",
            },
		};
	},//End of data
	created() {
       this.LoadShiyoDocumentData();

		// setTimeout(() => {
		// 	this.scale = '1'
		// 	this.loadPdf()
		// }, 1000);

        // setInterval(() => {
        //   if(  this.$refs.autoFocus) {
        //     this.$refs.autoFocus.focus();
        //   }
        // }, 1000);

		// window.addEventListener("resize", this.zoom);

	},
	watch:{

	},//End of watch
	methods: {
        zoom(){
			console.log('zooom')
			setTimeout(() => {
				// document.body.style.zoom = "reset";
				let wSize = window.visualViewport.scale;
				if( wSize == 1) {
					document.body.style.zoom = '100%'
				}
			}, 800);
		},
		getSizes ()  {
			const body = document.body;
			body.width = window.innerWidth;
			body.height = window.innerHeight;
			console.log(body.width, body.height);
		},
		handleUnpressCTRL() {
			window.removeEventListener('mousewheel', this.MouseWheelHandler);
		},
		handleCtrlKey( event ) {
		// console.log( event.keyCode )
		if( event.keyCode == 17 || event.which == '17' ) {
		console.log('You pressed CTRL')
		window.addEventListener('mousewheel', this.MouseWheelHandler );
		event.preventDefault();
		
		} else{
			console.log('Not press CTRL')
		}
		
		},
    
	MouseWheelHandler(  ) {
		var e = window.event || e;
		console.log('mousewheel_event', e)
		var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
		this.browserZoomLevel = Math.round( window.devicePixelRatio * 100 );
		if(delta > 0 ) {
		console.log('up')
		if (this.scale == "auto") {
			this.scale = "1";
		}
		else{
			let num = parseInt(this.scale);
			let b = String(num + 1);
			this.scale = b;
		}
		} else{
		console.log('down')
		if (this.scale != "1") {
			let num = parseInt(this.scale);
			let b = String(num - 1);
			this.scale = b;
		}
		
	}
		
	// e.preventDefault();
               
	},

    goToFeedback(){
        const url = `${this.api}shiyo/get_shiyo/${this.shiyoDocumentDetails._id}` 
            axios.defaults.headers.common["x-api-key"] =
                process.env.VUE_APP_API_KEY;
            axios.get(url).then(res=>{
                if(res.data) {
                console.log('line466', res.data)
                    if (this.$router.currentRoute.name != "feedback") {
                    this.CHANGE_SHIYO_FEEDBACK(res.data);
                    window.open(
                    `${this.linkURL}/feedback?id=${this.shiyoDocumentDetails._id}`,
                    "_blank"
                    );
                    }
                }
            })
		},

       downloadFile(pdfUrl) {  // Download function PDF using BLOB
        fetch(pdfUrl).then(resp => resp.arrayBuffer()).then(resp => {
            // set the blog type to final pdfconst file = new Blob([resp], {type: 'application/pdf'});
            // process to auto download itconst fileURL = URL.createObjectURL(file);
            const file = new Blob([resp], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            // link.download = this.shiyoDocumentDetails.fileName;
            link.download = `${this.shiyoDocumentDetails.shiyoNumber}-${this.shiyoDocumentDetails.productName}`
            link.click();
          });
       },
        downloadPDF(){
          this.downloadFile(this.src)
        },
        CloseWindow(){
            window.close();
        },

        promiseLoadShiyoDocumentData(){   // 2023-03-23
            return new Promise((resolve,reject) => {
                this.isPDFLoad = false;
                 axios.defaults.headers.common["x-api-key"] =
                    process.env.VUE_APP_API_KEY;
                  const url = `${this.api}shiyo/GetShiyoDocument/${this.$route.params.id}`
                  axios.get(url).then(res=>{
                     if( res.data ) {
                        resolve( res.data );
                     }
                  }).catch(err=>{
                    reject({ message: err.message})
                  })
            })
        },

        promiseGetPDFFile( shiyoDocumentDetails ) {  // 2023-03-23
            return new Promise((resolve,reject) => {
                var tunnelingAgent = tunnel.httpsOverHttp({
                    proxy: {
                    // Proxy settings
                    host: "hrdproxy.hrd-s.com",
                    port: 81,
                    proxyAuth: "administrator:system",
                    },
                });

                AWS.config.update({
                    httpOptions: { agent: tunnelingAgent },
                    region: "us-east-2",
                    credentials: config,
                });

                var s3 = new AWS.S3({
                    AccessKeyID: this.accessKeyId,
                    SecretAccessKey: this.secretAccessKey,
                    Region: "us-east-2",
                    params: {
                    bucket: "rulebook.files",
                    },
                });

                var options = {
                    Bucket: "rulebook.files",
                    Key: `Shiyo_pdf/${shiyoDocumentDetails.fileName}`
                };

                  s3.getObject(options, function(err, data) {
                    if (err) {
                     console.log(err, err.stack);
                     reject({ message: err.message })
                    }
                    else {
                    var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
                        type: `application/pdf`,
                    });
                    // type: `${data.ContentType};charset=utf-8`,

                    let url = URL.createObjectURL(blob);
                    //   self.src = url   // tanskie
                    resolve(url)
                              
                    }
                });
            })
        },
        async LoadShiyoDocumentData(){ // 2023-03-23
            try{
                this.isPDFLoad = false;
                this.isPDFLoadError = false;
                let shiyoDocumentDetails = await this.promiseLoadShiyoDocumentData();
                let srcPDF = await this.promiseGetPDFFile( shiyoDocumentDetails );
                document.title = `${shiyoDocumentDetails.shiyoNumber}-${shiyoDocumentDetails.productName}`
                this.src = srcPDF;
                this.shiyoDocumentDetails = shiyoDocumentDetails;
                this.isPDFLoad = true;

            }catch(err){
                 console.log( err.message );
                 alert( 'Error Attachment');
                 this.isPDFLoadError = true;
                 this.isPDFLoad = false;

            }
        },
        printPDF(){ // 2023-03-23
			var objFra = document.createElement('iframe'); // Create an IFrame.
			objFra.style.visibility = "hidden"; // Hide the frame.
			objFra.src = this.src; // Set source not done .pdf.
			objFra.onload = function(){
			objFra.contentWindow.focus(); // Set focus.
			objFra.contentWindow.print(); // Print it  
			};
			document.body.appendChild(objFra); // Add the frame to the web page.
		},

        LoadShiyoDocumentData2(){  // old function 2023-03-23
           this.isPDFLoad = false
           axios.defaults.headers.common["x-api-key"] =
            process.env.VUE_APP_API_KEY;
            const url = `${this.api}shiyo/GetShiyoDocument/${this.$route.params.id}`
            axios.get(url).then(res=>{
                this.shiyoDocumentDetails = res.data
                console.log(this.shiyoDocumentDetails)
                // Tabname
              
                this.isPDFLoad = true
            })
        },
		pdfPrint(){
			// let id = this.$refs.pdfView
			// console.log(id)
			// let objFra = document.getElementById(id);
			// objFra.contentWindow.focus();
			// objFra.contentWindow.print();

			// window.print(myFrame);
			// this.$refs.pdfView.print()
            window.open(this.src).print()
            window.close();

		},
		zoomIn() {

			if (this.scale == "auto") {
				this.scale = "1";
			
			}
			else{
				let num = parseInt(this.scale);
				let b = String(num + 1);
				this.scale = b;
			}
			
		},
		zoomOut() {
			if (this.scale != "1") {
				let num = parseInt(this.scale);
				let b = String(num - 1);
				this.scale = b;
			}
		},
		loadPdf(){

        let self = this;

        document.title = `${this.shiyoDocumentDetails.shiyoNumber}-${this.shiyoDocumentDetails.productName}`

        var tunnelingAgent = tunnel.httpsOverHttp({
            proxy: {
            // Proxy settings
            host: "hrdproxy.hrd-s.com",
            port: 81,
            proxyAuth: "administrator:system",
            },
        });

        AWS.config.update({
            httpOptions: { agent: tunnelingAgent },
            region: "us-east-2",
            credentials: config,
        });
        
        var s3 = new AWS.S3({
            AccessKeyID: this.accessKeyId,
            SecretAccessKey: this.secretAccessKey,
            Region: "us-east-2",
            params: {
            bucket: "rulebook.files",
            },
        });

        var options = {
            Bucket: "rulebook.files",
            Key: `Shiyo_pdf/${self.shiyoDocumentDetails.fileName}`
        };
        s3.getObject(options, function(err, data) {
            if (err) {
            console.log(err, err.stack);
            }
            // an error occurred
            else {
            // console.log(data, "s3Data");

            var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
                type: `${data.ContentType};charset=utf-8`,
            });

            let url = URL.createObjectURL(blob);
            self.src = url   // tanskie
        
            }
        });
        },
    addFavorite() {
        console.log( 'line673', this.shiyoData )
      let toInsert = {
        id: this.shiyoData.id,
        productName: this.shiyoData.productName,
        userId: this.userInfo.id,
        isFavorite: this.isFavorite,
      };

      let url = `${this.api}shiyo/addFavorite`;
      let url2 = `${this.api}shiyo/addFavoriteCount`;
      axios
        .post(url2, toInsert)
        .then(() => {
          axios
            .post(url, toInsert)
            .then((res) => {
              if (res.data == "Add Favorite") {
                Swal.fire({
                  icon: "success",
                  title: `${this.shiyoData.shiyoNumber} added to Favorites`,
                  showConfirmButton: false,
                  timer: 1200,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: `${this.shiyoData.shiyoNumber} deleted to Favorites`,
                  showConfirmButton: false,
                  timer: 1200,
                });
              }
            })
            .catch((err2) => {
              console.log(err2.message);
            });
        })
        .catch((err1) => {
          console.log(err1.message);
        });
    },
	},//End of methods
computed: {
    isFav() {
      if (this.isFavorite) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
},
};
</script>

<style scoped >
	@media only print {
		#app {
		display: none !important;
		}
	}

	#text {
		position: fixed;
		top: 90%;
		left: 5%;
		font-size: 25px;
		color: #ff0000;
		transform: translate(-10%, -10%);
		-ms-transform: translate(-30%, -30%);
	}

	.fStyle{
		font-size: 14px; 
		font-family: Arial, Helvetica, sans-serif;
	}
    .shiyo-viewer-link{
		height: 100%;
		/* overflow: hidden; */
	}
	.v-card {
		border-width: thin;
		display: block;
		max-width: 100%;
		outline: none;
		text-decoration: none;
		transition-property: box-shadow, opacity;
		overflow-wrap: break-word;
		position: relative;
		white-space: normal;
		height: 100%;
    }
</style>